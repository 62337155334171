.sticky-plans .row > * {
  padding: 0;
}
.sticky-plans {
  display: none;
  position: fixed;
  top: -100%;
  background-color: #ffff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.4);
  transition: all 0.5s ease;
  z-index: 2;
}
.sticky-plans.show {
  top: 13%;
}
.sticky-plans .price-card-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  border: 3px solid #fff;
  background: linear-gradient(
    130deg,
    #033070,
    var(--primary-color),
    var(--secondary-color)
  );
  transition: all 0.5s;
  width: 100%;
}
.sticky-plans .price-card-header h3 {
  color: #fff;
  margin: 5px 0;
  padding: 0;
  font-size: 0.9rem;
  font-weight: 500;
  text-transform: uppercase;
}
.sticky-plans .price-card-header p {
  color: #fff;
  font-size: 0.5rem;
}
.sticky-plans .price-card-header .order-btn {
  font-size: 0.8rem;
  text-transform: uppercase;
  text-decoration: none;
  margin: 5px 8px;
  padding: 5px;
  background-color: #ffff;
  border-radius: 4px;
  text-align: center;
  margin-top: auto;
  color: var(--primary-color);
}

.sticky-plans .price-card-header .price-card-tag {
  text-align: center;
  color: #fff;
  font-size: 1.1rem;
  font-weight: 600;
}
.sticky-plans .price-card-header .price-card-tag .period {
  font-size: 0.7rem;
}

@media only screen and (min-width: 700px) {
  .sticky-plans {
    display: flex;
    width: 100%;
  }
  .sticky-plans .row {
    width: 100%;
  }
  .sticky-plans .price-card-header .order-btn {
    font-size: 1rem;
    margin: 5px 10px;
    padding: 15px 15px;
  }
}
