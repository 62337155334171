.wordpress{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
}
.wordpress .wordpress-header{
    background-color: #042d66;
    background-image: var(--bg-1);
    color: #ffff;
    position: relative;
    top: -70px;
    height: 250px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
}
.wordpress .wordpress-header h2{
    color: #ffff;
    margin: 50px 0 25px 0;
    text-align: center;
    text-transform: capitalize;
}
.wordpress .wordpress-header p{
    padding: 5px 30px;
    text-align: center;
}
.wordpress .row{
    width: 100%;
    flex-wrap: wrap;
    padding: 0px 20px;
}
@media only screen and (min-width:600px){
    .wordpress .row .col-md-3{
        width: 100%;
        flex: 40%;
    }
}
@media only screen and (min-width:1000px){
    .wordpress .row .col-md-3{
        width: 100%;
        flex: 10%;
    }
}

