#blog{
    display: flex;
    flex-direction: column;
}
.item{
    width: 100%;
    background-color: blue;
    border: 2px solid black;
    padding: 20px;
}
@media screen and (min-width: 700px) {
    #blog{
        flex-direction: row;
        flex-wrap: wrap;
    }
  .item {
    flex: 50%;
  }
}

