#about .about{
    flex-direction: column;
}
#about .about .about-header{
    background-image: var(--bg-1);
    position: relative;
    top: -70px;
    height: 350px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    color: #ffff;
    border-bottom-right-radius: 30%;
    box-shadow: 10px 20px 30px rgba(0, 0, 0, .6);
}
#about .about .about-header h1{
    margin-top: 100px;
}
#about .about .about-header p{
    padding: 5px 35px;
    text-align: center;
}
#about .about .what-we-are{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}
#about .about .what-we-are .header{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
#about .about .what-we-are .header h3{
    font-size: 1.7rem;
    font-weight: 600;
    text-transform: capitalize;
}
#about .about .what-we-are .header p{
    padding: 5px 25px; 
    text-align: justify;
    text-transform: none;
}
#about .about .what-we-are .what-we-are-cards{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 8px 20px;
}
#about .about .what-we-are .what-we-are-cards .what-we-are-card{
    flex: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 250px;
    width: 80%;
    margin: 8px 20px;
    padding: 10px;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 4px;
    transition: all .4s;
}
#about .about .what-we-are .what-we-are-cards .what-we-are-card:hover{
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, .3);
}
#about .about .what-we-are .what-we-are-cards .what-we-are-card h4{
    margin: 10px;
    text-transform: capitalize;
}
#about .about .what-we-are .what-we-are-cards .what-we-are-card img{
    width: 150px;
}


/* media query */
@media only screen and (min-width:450px){
    #about .about .about-header{
        height: 280px;
    }
}
@media only screen and (min-width:768px){
    #about .about .about-header{
        height: 220px;
    }
    #about .about .about-header p{
    padding: 5px 65px;
    text-align: center;
    }
    #about .about .what-we-are .what-we-are-cards .what-we-are-card{
        flex: 20%;
    }
}
/* end media query */