
svg{
  width: 200px;
  height: fit-content;
}
#Main_Logo #Host{
  margin-left: 10px;
}

svg #Main_Logo #Host{
  fill: #ffff;
  transition: all .4s;
}
svg #Main_Logo .Host.colorChange{
  fill: rgb(0, 0, 0);
  stroke: none;
}