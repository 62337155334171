.shared{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
}
.shared .shared-header{
    background-color: #042d66;
    background-image: var(--bg-1);
    position: relative;
    top: -70px;
    height: 250px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
}
.shared .shared-header h2{
    color: #ffff;
    margin: 30px 0 10px 0;
    text-align: center;
    text-transform: capitalize;
}
.shared .shared-header p{
    color: #ffff;
    margin: 0px 40px;
    text-align: center;
    text-transform: capitalize;
    font-size: .8rem;
}
.shared .row{
    width: 100%;
    flex-wrap: wrap;
    padding: 0px 20px;
}
@media only screen and (min-width:600px){
    .shared .row .col-md-3{
        width: 100%;
        flex: 40%;
    }
}
@media only screen and (min-width:1000px){
    .shared .row .col-md-3{
        width: 100%;
        flex: 10%;
    }
}

