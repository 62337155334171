#weoffer .weoffer{
    flex-direction: column;
    margin-top: 10px;
    height: fit-content;
}
#weoffer .weoffer .weoffer-header{
    text-align: center;
}
#weoffer .weoffer .section-header{
    color: black;
    display: inline-block;
}
#weoffer .weoffer .section-header::after{
    content: '';
    display: block;
    width: 0%;
    height: 2px;
    background: var(--primary-color);
    transition: all .8s;
}
#weoffer .weoffer:hover .section-header::after{
    width: 100%; 
}

#weoffer .weoffer .section-header-content{
    text-align: left;
    margin: 10px 20px;
    font-size: .8rem;
    line-height: 25px;
}
#weoffer .weoffer .weoffer-cards{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px 20px;
    max-width: 1300px;
}
#weoffer .weoffer .weoffer-cards .weoffer-card{
    display: flex;
    width: 100%;
    height: 150px;
    margin: 10px 0px;
    padding: 10px;
    box-shadow: 5px 5px 5px var(--primary-color);
    justify-content: center;
    align-items: center;
    transition: all .4s;
}
#weoffer .weoffer .weoffer-cards .weoffer-card:hover{
    color: #ffff;
    background-color: var(--primary-color);
    box-shadow: 5px 5px 5px rgba(0, 0, 0, .5);
}
#weoffer .weoffer .weoffer-cards .weoffer-card:hover .card-image {
    filter: brightness(0) invert(1);

}
#weoffer .weoffer .weoffer-cards .weoffer-card .card-image {
    width: 150px;
    height: 80px;
}
#weoffer .weoffer .weoffer-cards .weoffer-card .card-image img {
    max-width: 100%;
    height: 100%;
}
#weoffer .weoffer .weoffer-cards .weoffer-card .card-content{
    margin: 3px 20px;
    text-align: left;
}
#weoffer .weoffer .weoffer-cards .weoffer-card .card-content .weoffer-card-header{
    font-weight: bold;
    font-size: 1rem;
    text-transform: uppercase;
}
#weoffer .weoffer .weoffer-cards .weoffer-card .card-content .card-subcontent{
    font-size: .9rem;
}

@media screen and (min-width: 756px) {
    #weoffer .weoffer .weoffer-header .section-header-content{
        text-align: justify;
        min-width: 600px;
    }
    #weoffer .weoffer .weoffer-header .section-header-content p{
        font-size: .9rem;
    }
    #weoffer .weoffer .weoffer-cards{
        flex-direction: row;
        flex-wrap: wrap;
        margin: 10px 30px;
    }
    #weoffer .weoffer .weoffer-cards .weoffer-card {
        flex: 40%;
        padding: 10px 10px;
        margin: 10px 15px;
    }
}
@media only screen and (min-width:1200px){
    #weoffer .weoffer{
        flex-direction: column;
    }
    #weoffer .weoffer .weoffer-header{
        flex: 200px;
    }
    #weoffer .weoffer .weoffer-header .section-header-content{
        text-align: justify;
        margin: auto 20px;
        font-size: 1rem;
    }
    #weoffer .weoffer .weoffer-cards{
        flex-direction: row;
        flex-wrap: wrap;
    }
    #weoffer .weoffer .weoffer-cards .weoffer-card {
        flex: 40%;
        margin: 10px 20px;
    }
}