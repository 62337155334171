#whyCloud .whyCloud{
    background-color: #042d66;
    background-image: var(--bg-1);
    flex-direction: column;
    margin: 0px 0px;
    padding: 30px;
    text-align: center;
    height: fit-content;
    border-radius: 5px;
}
#whyCloud .whyCloud .whyCloud-header .section-header{
    display: inline-block;
    color: #fff;
}
#whyCloud .whyCloud .whyCloud-header h1::after{
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background:#fff;
    transition: all .8s;
}
#whyCloud .whyCloud:hover .whyCloud-header h1::after{
    width: 100%;
}
#whyCloud .whyCloud .whyCloud-cards{
    display: flex;
    flex-direction: column;
    margin: 10px 10px;
}

#whyCloud .whyCloud .whyCloud-cards .whyCloud-card{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: fit-content;
    border-radius: 20px;
    margin: 10px 0;
    background-color: #ffff;
    box-shadow:6px 6px 10px var(--secondary-color);
    transition: all .5s;
}
#whyCloud .whyCloud .whyCloud-cards .whyCloud-card:hover {
    box-shadow:none;
    transform: skewY(175deg);
}
#whyCloud .whyCloud .whyCloud-cards .whyCloud-card .card-image {
    font-size: 80px;
}
#whyCloud .whyCloud .whyCloud-cards .whyCloud-card .card-image img {
    width: 90px;
    height: 90px;
    align-self: center;
    margin-top: 3px;
}
#whyCloud .whyCloud .whyCloud-cards .whyCloud-card .card-header {
    display: inline-block;
    font-size: 1.2rem; 
    padding: 1px;
    text-transform: uppercase;
}
#whyCloud .whyCloud .whyCloud-cards .whyCloud-card .card-header::after {
    content: '';
    display: block;
    width: 0%;
    height: 2px;
    background: var(--primary-color);
    transition: width .3s;

}
#whyCloud .whyCloud .whyCloud-cards .whyCloud-card:hover .card-header::after{
    width: 100%;
}
#whyCloud .whyCloud .whyCloud-cards .whyCloud-card .card-content {
    font-size: 1rem; 
    text-align: center;
    padding: 10px 10px;
}

@media only screen and (min-width: 768px){
    #whyCloud .whyCloud{
    height: fit-content;
}
    #whyCloud .whyCloud .whyCloud-cards{
    flex-direction: row;
    flex-basis: 35%;
    height: fit-content;
    margin: 20px 40px;
    width: 100%;
    border-radius: 0;
    }
    #whyCloud .whyCloud .whyCloud-cards .whyCloud-card{
        margin: 0px 30px;
        width: 100%;
    }
}
@media only screen and (min-width: 1200px){
    #whyCloud .whyCloud .whyCloud-cards{
    flex-direction: row;
    height: fit-content;
    width: 100%;
    border-radius: 0;
    }
    #whyCloud .whyCloud .whyCloud-cards .whyCloud-card{
        margin: 10px 20px;
        width: 100%;
    }
}


/* keyframes */