/* hero section */
#hero{
    background-color: #044db4;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 200 200'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='100' y1='33' x2='100' y2='-3'%3E%3Cstop offset='0' stop-color='%23000' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23000' stop-opacity='1'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='100' y1='135' x2='100' y2='97'%3E%3Cstop offset='0' stop-color='%23000' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23000' stop-opacity='1'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='%23034199' fill-opacity='0.6'%3E%3Crect x='100' width='100' height='100'/%3E%3Crect y='100' width='100' height='100'/%3E%3C/g%3E%3Cg fill-opacity='0.5'%3E%3Cpolygon fill='url(%23a)' points='100 30 0 0 200 0'/%3E%3Cpolygon fill='url(%23b)' points='100 100 0 130 0 100 200 100 200 130'/%3E%3C/g%3E%3C/svg%3E");
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-content: center;
    height: 300px;
    width: 100%;
    margin-top: -70px;
    border-bottom-right-radius: 50%;
    z-index: -1;
    box-shadow: 10px 20px 30px rgba(0, 0, 0, .6);
}
/* #hero::after{
    content: '';
    display: flex;
    position:absolute;
    align-self: center;
    justify-self: center;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: var(--primary-color);
    opacity: .5;
    z-index: -3;
} */
#hero .hero{
    margin: 0;
    padding: 0 20px;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
}
#hero .hero .hero-header{
    display: flex;
    flex-direction: column;
}
#hero .hero .hero-img{
    display: none;
}
#hero .hero .hero-header .hero-head{
    font-size: 1.9rem;
    color: #fff;
    font-weight: 600;
}
#hero .hero .hero-header h1{
    display: flex;
    width: fit-content;
    font-size: 1.5rem;
    position: absolute;
    color: #fff;
    animation: text_reveal  .5s ease forwards;
    animation-delay: 1s;
    top: 20%;
}
/* end hero section */
@media only screen and (min-width:600px){
    #hero .hero{
        justify-content: flex-start;
        flex-direction: row;
    }
    #hero .hero .hero-header{
        width: inherit;
    }
    #hero .hero .hero-img{
        display: flex;
        position: relative;
        width: 80%;
        height: 80%;   
    }
    #hero .hero .hero-img img{
        position: absolute;
        width: 100%;
        height: 100%;
        animation: Hone-Banner-Slide 2s forwards;
    }
}
@media only screen and (min-width:700px){
    /* #hero .hero .hero-img img{
    width: 300px;
    height: 300px;
    } */
}
@media only screen and (min-width:756px){
    #hero{
        margin-top: -70px;
        height: 500px;
    }
    #hero .hero .hero-header .hero-head{
    font-size: 2.3rem;
    color: #fff;
    font-weight: 600;
    }
}
@media only screen and (min-width:900px){
    /* #hero .hero .hero-img img{
    width: 500px;
    height: 400px;
    } */
}
/* keyframes */
@keyframes Hone-Banner-Slide{
    0%{
        left: 5%;
        opacity: 0;
    }
    100%{
        left: 0;
        opacity: 1;
    }
}
/* end keyframes */