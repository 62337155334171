#price .price{
    flex-direction: column;
    text-align: center;
    margin-top: 60px;
}
#price .price .price-header{
    text-transform: uppercase;
}
#price .price .price-header h3{
    display: block;
    color: #000;
	font-weight: bold;
    font-size: 1.3rem;
    margin: 10px 0px;
    animation: blinkingBackground 2s infinite;
}
#price .price .price-header .section-header{
    color: black;
    display: inline-block;
}
#price .price .price-header .section-header::after{
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background:var(--primary-color);
    transition: all .8s;
}
#price .price:hover .price-header .section-header::after{
    width: 100%;
}
#price .price .price-cards{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 0px 20px;
}
#price .price .price-cards .price-card{
    flex: 100%;
    height: auto;
    padding: .25rem;
    transition: all .5s;
}



/* Media Query */
@media only screen and (min-width:550px){
    #price .price .price-cards .price-card{
        flex: 40%;
    }
}
@media only screen and (min-width:768px){
    #price .price{
    flex-direction: column;
    }
    #price .price .price-cards .price-card{
        flex: 30%;
    }
}
@media only screen and (min-width:1200px){
    #price .price .price-cards{
        flex-direction: row;
    }
    #price .price .price-cards .price-card{
        flex: 25%;
    }
    #price .price .price-cards .price-card .price-card-header h3{
    font-size: 1.4rem;
    }
    #price .price .price-cards .price-card .price-card-header p{
        font-size: 1rem;
    }
}



/* Key frames */
@keyframes blinkingBackground{
		0%		{color: #10c018;}
		20%		{color: #1056c0;}
		40%		{color: #ffe819;}
		60%		{color: #ef0a1a;}
		80%		{color: #fa0ec7;}
		100%	        {color: #04d543;}
	}
/* end key frames */