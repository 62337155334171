#services .services{
    display: flex;
    flex-direction: column;
    background-color: #042d66;
    background-image: var(--bg-1); 
}
#services .services .services-headers{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
}
#services .services .services-headers .services-header{
    display: inline-block;
}
#services .services .services-headers .services-header::after{
    content: '';
    display: block;
    width: 0%;
    height: 2px;
    background: #fff;
    transition: all .8s;
}
#services .services:hover .services-headers .services-headers::after{
    width: 100%; 
}
#services .services .services-headers .services-header-content p{
    font-size: 1.3rem;
    text-align: center;
}
#services .services .services-content{
    margin: 5px;
}
#services .services .services-content .services-cards{
    display: flex;
    flex-direction: column;
}
#services .services .services-content .services-cards .services-card{
    display: flex;
    flex-direction: column;
    align-content: center;
    background-color: #fff;
    width: 100%;
    padding: 10px 10px;
    transition: all .4s;
    margin: 10px 0px;
    text-transform: uppercase;
}
#services .services .services-content .services-cards .services-card:hover{
    background-color: black;
    color: #fff;
    box-shadow: 5px 5px 5px rgb(255, 255, 255);
}
#services .services .services-content .services-cards .services-card:hover .service-card-header img{
    filter: brightness(0) invert(1);
}
#services .services .services-content .services-cards .services-card .service-card-header{
    text-transform: uppercase;
    font-size: 1rem;
}
#services .services .services-content .services-cards .services-card .card-content{
    text-transform: capitalize;
    font-size: .9rem;
    margin: 10px 8px;
}
#services .services .services-content .services-cards .services-card .card-content .card-content-list{
    list-style-type: square;
    color: var(--primary-color);
    padding:10px;
}
#services .services .services-content .services-cards .services-card:hover .card-content .card-content-list{
    color: #fff;
}
@media only screen and (min-width:450px){
    #services .services .services-content .services-cards{
    flex-direction: row;
    flex-wrap: wrap;
    }
    #services .services .services-content .services-cards .services-card .service-card-header h3{
    font-size: 1.2rem;
    }
    #services .services .services-content .services-cards .services-card{
        flex: 40%;
        margin: 10px 15px;
    }
}
@media only screen and (min-width:900px){
    #services .services .services-content .services-cards{
    flex-direction: row;
    flex-wrap: nowrap;
    }
    #services .services .services-content .services-cards .services-card{
        flex: 40%;
        width: auto;
        margin: 10px 10px;
    }
    #services .services .services-content .services-cards .services-card .service-card-header h3 {
        font-size: 1.2rem;
    }
}
@media only screen and (min-width:1200px){
    #services .services .services-content .services-cards{
    flex-direction: row;
    flex-wrap: nowrap;
    }
    
    #services .services .services-content .services-cards .services-card{
        flex: 40%;
        width: 280px;
        margin: 10px;
    }
    
}