 .price-card{
    width: 100%;
    height: 100%;
    margin: 0px 0px;
    padding: 0px ;
    overflow: hidden;
    transition: all .5s;
    display: flex;
    flex-direction: column;
    max-width: 20rem;
}
.price-card .powered{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: linear-gradient(90deg,var(--primary-color),var(--secondary-color));
    color: #fff;
}
.price-card .powered .powered-img{
    margin: 0 5px;
}
 .price-card:hover{
    background-color: var(--primary-color);
    box-shadow: 0 0 6px 6px rgba(0, 0, 0,.3) ;
}
.price-card .price-card-header{
    display: flex;
    max-height: 224px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
    border-bottom: 3px solid #fff;
    background: linear-gradient(130deg,var(--third-color),var(--primary-color),var(--secondary-color));
    transition: all .5s;
}
 .price-card .price-card-header h3{
    color: #fff;
    margin: 5px 0;
    padding: 0;
    font-size: 1.2rem;
    font-weight: 500;
    text-transform: uppercase;
}
 .price-card .price-card-header p{
    color: #fff;
    font-size: .9rem;
    text-transform: capitalize;
    text-align: center;
}
 .price-card .price-card-header .price-card-tag{
    text-align: center;
    color: #fff;
    font-size: 1.4rem;
    font-weight: 800;
}
 .price-card .price-card-header .price-card-tag .period{
    font-size: 1rem;
}
.price-card:hover .price-card-header{
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
}
 .price-card .price-card-content{
    position: relative;
    max-width: 100%;
    font-weight: 500;
    color: var(--primary-color);
    }
 .price-card:hover .price-card-content{
    color: #fff;
}
 .price-card .price-card-content ul{
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 20px 0px;
    justify-content: center;
    align-items: center;
}
 .price-card .price-card-content ul li{
    list-style: none;
    font-size: .9rem;
    text-transform: capitalize;
    padding: 8px 0px;
    align-self: center;
}
 .price-card .order-btn{
    font-size: 1rem;
    text-transform: uppercase;
    text-decoration: none;
    margin: 15px 40px;
    padding: 15px 20px;
    background-color: var(--primary-color);
    border-radius: 5px;
    text-align: center;
    margin-top: auto;
}
 .price-card:hover .order-btn{
    background-color: #fff;
}
 .price-card .order-btn{   
    color: #fff;
    text-decoration: none;
}
 .price-card:hover .order-btn{
    color: var(--primary-color);
}
@media only screen and (min-width:768px){
    #price .price{
    flex-direction: column;
    }
}
@media only screen and (min-width:1200px){
     .price-cards-group .price-card{
        width: 18rem;
        margin: 0;
    }
     .price-card .price-card-header h3{
    font-size: 1.3rem;
    }
     .price-card .price-card-header p{
        font-size: .9rem;
    }
}

/* keyframes */
/* end keyframes */