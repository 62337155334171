/* Header section */
.brand {
  font-size: 2rem;
  color: #ffff;
  text-decoration: none;
  font-weight: 700;
}
#header {
  position: sticky;
  z-index: 3;
  left: 0;
  top: -1px;
  width: 100%;
  height: 70px;
}
#header .header {
  height: 70px;
  background-color: transparent;
  transition: 0.4s ease background-color;
}
#header .header.colorChange {
  background-color: #ffff;
  box-shadow: 0px 10px 5px rgba(0, 0, 0, .5);
}
#header .nav-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 0 10px;
}
#header .nav-list ul {
  list-style: none;
  position: absolute;
  background-color: var(--third-color);
  width: 78%;
  height: 90vh;
  left: -100%;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 0;
  z-index: 1;
  overflow-x: hidden;
  transition: 0.5s ease left;
}
#header .nav-list ul.active {
  left: 0;
}

#header .nav-list ul .mynav-links {
  font-size: 1.1rem;
  font-weight: 500px;
  text-decoration: none;
  color: #ffff;
  text-transform: capitalize;
  padding: 10px;
  display: block;
}
#header .nav-list ul .mynav-links::after {
  content: attr(data-after);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  color: rgba(240, 240, 255, 0.021);
  font-size: 3rem;
  letter-spacing: 40px;
  z-index: -1;
  font-weight: bold;
  transition: 0.4s ease letter-spacing;
}
#header .nav-list ul li:hover .mynav-links::after {
  transform: translate(-50%, -50%) scale(1);
  letter-spacing: initial;
}

.mynav-links:hover {
  color: var(--primary-color);
  transition: all 0.3s ease-out;
}
#header .nav-list .hamburger {
  height: 60px;
  width: 60px;
  border: 3px solid #ffff;
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 100;
  cursor: pointer;
  transform: scale(0.8);
  margin-right: 5px;
}
#header .nav-list .hamburger.colorChange {
  border: 3px solid var(--primary-color);
}
#header .nav-list .hamburger:after {
  position: absolute;
  height: 100%;
  width: 100%;
  content: "";
  border-radius: 50%;
  border: 3px solid #ffff;
  animation: hamburger-pulse 1s ease infinite;
}
#header .nav-list .hamburger .fa-times {
  color: #ffff;
  font-size: 30px;
}
#header .nav-list .hamburger .fa-bars {
  color: #ffff;
  font-size: 30px;
}
#header .nav-list .hamburger .fa-times.colorChange {
  color: var(--primary-color);
  font-size: 30px;
}
#header .nav-list .hamburger .fa-bars.colorChange {
  color: var(--primary-color);
  font-size: 30px;
}
#header .nav-list ul .login-li {
  width: 50%;
  text-align: center;
}
#header .nav-list ul .login-li .login-btn {
  border: 2px solid #ffff;
  padding: 10px 20px;
}

/* End of header section */

/* popover section */
.popover-body {
  padding: 0px;
}
/* hosting popover */
#hosting-popover{
    width: 300px;
}
#hosting-popover::before{
   content: "";
  position: absolute;
  top: -7px;
  left: 50%;
  width: 17px;
  height: 17px;
  -webkit-transform: translate(-50%, 0) rotate(-315deg);
  transform: translate(-50%, 0) rotate(-315deg);
  border-radius: 3px;
  background-color: #fff;
  z-index: -1; 
}
.hosting-list {
  display: flex;
  flex-direction: column;
  padding: 0px;
  margin: 1px;
  text-transform: uppercase;
}
.hosting-list li {
  list-style: none;
  text-decoration: none;
  width: 100%;
  padding: 8px 15px;
  border-bottom: 1px solid var(--primary-color);
  transition: all 0.4s;
}
.hosting-list li a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    color: var(--primary-color);
    transition: all .4s;
}
.hosting-list li:hover a {
  transform: scale(1.1);
}

/* end hosting popover */
/* domain popover */
#domain-popover::before {
  content: "";
  position: absolute;
  top: -7px;
  left: 50%;
  width: 17px;
  height: 17px;
  -webkit-transform: translate(-50%, 0) rotate(-315deg);
  transform: translate(-50%, 0) rotate(-315deg);
  border-radius: 3px;
  background-color: #fff;
  z-index: -1;
}
.domain-list {
  display: flex;
  flex-direction: column;
  padding: 0px;
  margin: 1px;
  text-transform: uppercase;
}
.domain-list li {
  list-style: none;
  text-decoration: none;
  width: 100%;
  padding: 8px 15px;
  border-bottom: 1px solid var(--primary-color);
  transition: all 0.4s;
}
.domain-list li:hover {
  background-color: var(--primary-color);
}
.domain-list li:hover a {
  color: #ffff;
}
.domain-list li a {
  text-decoration: none;
  color: var(--primary-color);
}
/* end domain popover */
/* end popover section */
/* media query for tablet */
@media only screen and (min-width: 924px) {
  .brand > h1 {
    font-size: 2.3rem;
    color: #fff;
    text-decoration: none;
    font-weight: 700;
  }
  #header .header {
    width: 100%;
    height: 75px;
  }
  #header .nav-bar {
    justify-content: space-between;
    margin-top: 0px;
  }
  #header .nav-list .hamburger {
    display: none;
  }
  #header .nav-list ul {
    position: initial;
    display: flex;
    flex-direction: row;
    height: auto;
    width: fit-content;
    background-color: transparent;
    align-items: center;
    justify-content: center;
    margin-top: 13px;
    border-bottom-right-radius: 0;
  }
  #header .nav-list ul li {
    display: table;
    transition: all 0.3s;
    margin: 2px 8px;
  }
  #header .nav-list ul li .mynav-links {
    font-size: 1rem;
    color: #fff;
    border: 1px solid transparent;
    transition: all 0.3s;
  }
  #header .nav-list ul.colorChange li .mynav-links {
    color: var(--primary-color);
  }
  #header .nav-list ul.colorChange li:hover {
    background-color: var(--primary-color);
  }
  #header .nav-list ul.colorChange li:hover .mynav-links {
    color: #fff;
  }
  #header .nav-list ul.colorChange li .mynav-links.active {
    color: #ffff;
    background-color: var(--primary-color);
  }
  #header .nav-list ul li:hover {
    background-color: #fff;
  }
  #header .nav-list ul li:hover .mynav-links {
    color: var(--primary-color);
  }
  #header .nav-list ul li .mynav-links.active {
    color: var(--primary-color);
    background-color: #ffff;
  }
  #header .nav-list ul .mynav-links::after {
    display: none;
  }
  #header .nav-list ul .login-li {
    width: 0%;
    text-align: center;
  }
  #header .nav-list ul .login-li .login-btn {
    border: 2px solid #fff;
    padding: 10px 20px;
  }
  #header .nav-list ul.colorChange .login-li .login-btn {
    border: 2px solid var(--primary-color);
    padding: 10px 20px;
  }
}
/* End media query for tablet  */

/* media query for desktop */
@media only screen and (min-width: 1200px) {
}
/* End media query for desktop  */

@keyframes hamburger-pulse {
  0% {
    opacity: 1;
    transform: scale(1.1);
  }
  100% {
    opacity: 0;
    transform: scale(1.6);
  }
}

@keyframes Rotate__rotation__1 {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
