.footer-container {
  background-color: #242424;
  padding: 4rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.footer-links{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
.footer-link-wrapper{
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    width: 100%;
}
.footer-link-items{
    flex: 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px;
    text-align: left;
    width: 160px;
    box-sizing: border-box;
}
.footer-link-items h2{
    margin-bottom: 16px;
    font-size: 1.3rem;
    text-transform: uppercase;
}
.footer-link-items .fas{
    color: #fff;
    font-size: 1.5rem;
    margin: 10px;
}
.footer-link-items a{
    color: #fff;
    text-decoration: none;
    margin-bottom: 8px;
    font-size: .8rem;
    text-transform: capitalize;
}
.footer-link-items a:hover{
    color: #e9e9e921;
    transition: all .1s ease-out;
}
.footer-link-items .footer-contactus-list li{
  display: flex;
  align-items: center;
}
.footer-email-form h2{
    margin-bottom: 32px;
}
.footer-input::placeholder{
    color: #b1b1b1;
}

/* Social Icon */
.social-icon-link{
    color: #fff;
    font-size: 34px;
}
.social-media{
    width: 100%;
}
.social-logo{
    text-decoration: none;
    font-size: 2rem;
    color: #ffff;
}
.social-media-wrap{
    display:flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1000px;
    margin: 40px auto 0 auto;
}
.social-media-wrap .footer-logo{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.social-media-wrap .footer-payment{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.social-media-wrap .footer-payment .payment-icons{
    display: flex;
    width: 100%;
    justify-content: space-around;
}
.social-media-wrap .footer-payment h3{
  color: #ffff;
  font-size: 1rem;
}
.social-media-wrap .footer-payment .payment-icons img{
    width: 40px;
}
.social-icons{
    display: flex;
    justify-content: center;
}

.social-icon{
    display: flex;
  background: #e3edf7;
  height: 45px;
  width: 45px;
  margin: 0 15px;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  box-shadow: 6px 6px 10px -1px rgba(0,0,0,0.15),
              -6px -6px 10px -1px rgba(255,255,255,0.7);
  border: 1px solid rgba(0, 0, 0, 0);
  transition: all 0.5s
}
.social-icon i{
    font-size: 20px;
  color:#777;
  transition: transform 0.5s;
}
.social-icon:hover{
    box-shadow:inset 4px 4px 6px -1px rgba(0,0,0,0.2),
    inset -4px -4px 6px -1px rgba(255,255,255,0.7),
        -0.5px -0.5px 0px -1px rgba(255,255,255,1),
        0.5px 0.5px 0px rgba(0,0,0,0.15),
        0px 12px 10px -10px rgba(0,0,0,0.05);
    border: 1px solid rgba(255, 0, 0, 0.1);
    transform: translateY(2px);
}
.social-icon:hover i{
  transform: scale(0.90);
}
.social-icon:hover .fa-facebook-f{
  color: #3b5998;
}
.social-icon:hover .fa-twitter{
  color: #00acee;
}
.social-icon:hover .fa-whatsapp{
  color: #4fce5d;
}
.social-icon:hover .fa-instagram{
  color: #f14843;
}
.social-icon:hover .fa-youtube{
  color: #f00;
}
.website-rights {
  color: #fff;
  margin-bottom: 16px;
}

@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 1rem;
  }
  .footer-link-wrapper{
    flex-direction: row;
    flex-wrap: wrap;
  }
  .footer-link-items{
      flex: 40%;
  }
  
  .social-media-wrap {
    flex-direction: column;
    height: 250px;
  }
}